import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/impact-tech-growth-san-diego-real-estate"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_The Impact of Tech Industry Growth on San Diego Real Estate.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                When you think of San Diego, what comes to mind? Gorgeous beaches? Mild year-round
                weather? Or maybe a growing tech hub, as this Southern California city is quickly
                making a name for itself in the technology industry.
              </CommonParagraph>
              <CommonParagraph>
                Its burgeoning tech sector has been a key driver in this coastal community’s real
                estate market, not only reshaping demand but presenting new opportunities for
                investors. Here, we’ll explore how San Diego’s tech growth is impacting real estate
                in the area.
              </CommonParagraph>
              <Heading type="h2">An Overview of San Diego’s Tech Industry Growth</Heading>
              <CommonParagraph>
                San Diego has experienced significant growth in its tech industry in recent years,
                with a strong presence in industries like:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px]">
                <li>Telecommunications</li>
                <li>Cybersecurity</li>
                <li>Biotechnology</li>
                <li>Software development</li>
              </ul>
              <CommonParagraph>
                That presence has attracted not only start-ups but major companies. Leading firms
                like Qualcomm and Illumina even have their roots there, and being so close to
                research institutions, including the University of California, San Diego, further
                encourages tech innovation.
              </CommonParagraph>
              <CommonParagraph>
                With such an industry boom comes employment opportunities, and skilled professionals
                from across the country are now being drawn to San Diego. As a result, the demand
                for housing has surged, creating a ripple effect across the real estate market that
                everyone in the city may be feeling.
              </CommonParagraph>
              <Heading type="h2">Impact on the San Diego Housing Market</Heading>
              <Heading type="h3">Demand</Heading>
              <CommonParagraph>
                As the tech industry continues to grow and draw in more and more professionals, the
                demand for housing has also grown. That’s especially true in areas close to tech
                campuses and{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities"
                  text="San Diego communities"
                />{" "}
                known for innovation, including La Jolla and Sorrento Valley.
              </CommonParagraph>
              <CommonParagraph>
                The booming tech industry and resulting housing demand have contributed to the rate
                at which new residential developments are being built. Luxury condos and townhomes
                have become popular construction projects, as they create housing opportunities for
                more people than a single-family home does.
              </CommonParagraph>
              <Heading type="h3">Pricing</Heading>
              <CommonParagraph>
                An increase in the demand for housing can also mean an increase in home prices and
                more competition. As the tech market grows, pricing and competition will likely
                remain fierce. That makes it especially important to be prepared when{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego/"
                  text="house hunting"
                />
                . By having a mortgage lender lined up and finances in order, you’re better prepared
                to make an offer when you find a house that suits your needs–and in a hot market
                like San Diego, time is of the essence.
              </CommonParagraph>
              <CommonParagraph>
                The rental market is also feeling the pressure of rising prices, creating
                opportunities for homeowners to turn their primary residence into a potentially
                lucrative investment property.
              </CommonParagraph>
              <Heading type="h3">Investment Opportunities</Heading>
              <CommonParagraph>
                Current San Diego homeowners aren’t the only ones who can benefit from this booming
                market. The industry’s growth means houses in tech-dense areas offer strong
                appreciation potential. And with more professionals relocating for technology jobs,
                homes in these regions are likely to continue appreciating. That makes them great
                real estate investment opportunities.
              </CommonParagraph>
              <CommonParagraph>
                If you’re looking to start or grow your investment portfolio in San Diego,
                multi-family units or properties that can be converted into multiple rental spaces
                can be great options. Instead of only being able to rent to one family at a time,
                you can rent to a few, creating consistent cash flow from multiple sources.
              </CommonParagraph>{" "}
              <CommonParagraph>
                The rise of remote work and hybrid schedules can also be used to your investment
                advantage. Five years ago, many neighborhoods on the outskirts of the city were
                considered as being too far away from the hub of things. However, for those needing
                to be in the area for occasional meetings or commuting to the office only two or
                three times a week, a longer commute may no longer be a dealbreaker.
              </CommonParagraph>
              <CommonParagraph>
                Purchasing an investment property in a neighborhood just outside of a tech hub could
                be a savvy move.
              </CommonParagraph>
              <Heading type="h2">Benefit From the Growing Tech Industry</Heading>
              <CommonParagraph>
                Whether you’re looking to partner with a local who can help you find your Home Sweet
                Home,{" "}
                <BlogLink
                  url="https://selbysellssd.com/sell-your-san-diego-home/"
                  text="move out of the area"
                />{" "}
                and get top dollar while doing so, or start building wealth through rental
                properties, The Selby Team can assist.
              </CommonParagraph>
              <CommonParagraph>
                Our experts are standing by, ready to help you leverage San Diego’s tech boom to
                fulfill your real estate needs. <ContactSlideoutLink text="Let’s get started" />!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
